
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import HeroSection from 'components/landing/HeroSection';
import HowTo from 'components/landing/HowTo';
import LandingPageFaq from 'components/landing/LandingPageFaq';
import Sponsors from 'components/landing/Sponsors';
import WhyRevokeCash from 'components/landing/WhyRevokeCash';
import LandingLayout from 'layouts/LandingLayout';
import { defaultSEO } from 'lib/next-seo.config';
import type { NextPage } from 'next';
import { LogoJsonLd, NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
const LandingPage: NextPage = () => {
    const { t } = useTranslation();
    return (<>
      <NextSeo {...defaultSEO} title={t('common:meta.title')} description={t('common:meta.description')}/>
      <LogoJsonLd logo="https://revoke.cash/assets/images/revoke-icon.svg" url="https://revoke.cash"/>
      <LandingLayout>
        <div className="flex flex-col items-center gap-8">
          <HeroSection />
          <HowTo />
          <WhyRevokeCash />
          <LandingPageFaq />
          <Sponsors />
        </div>
      </LandingLayout>
    </>);
};
export default LandingPage;

    async function __Next_Translate__getStaticProps__18b94329e33__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18b94329e33__ as getStaticProps }
  